import { Route, Routes } from "react-router-dom";
import AboutPages from "./pages/AboutPages";
import HomePages from "./pages/HomePages";
import ContactPages from "./pages/ContactPages";
import NavBarComponent from "./component/NavBarComponent";
import FooterComponent from "./component/FooterComponent";
import Error404Page from "./pages/Error404Page";

function App() {
  return (
    <div className="main-top  mt-5">
      <NavBarComponent />
      <Routes>
        <Route path="/" element={<HomePages />} />
        <Route path="/about" element={<AboutPages />} />
        <Route path="/contact" element={<ContactPages />} />
        <Route path="*" element={<Error404Page />} />
      </Routes>
      <FooterComponent />
    </div>
  );
}

export default App;
