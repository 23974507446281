import React from "react";
import { MainSliderImages, OurService, WhatWeDo } from "../component/DataJson";
import PartnersComponents from "./../component/PartnersComponents";
import ProjectGalleryComponent from "../component/ProjectGalleryComponent";

export default function HomePages() {
  return (
    <>
      <main>
        <div className="banner_w3lspvt position-relative mt-5">
          <div className="container">
            <div id="my-slider" className="carousel slide" data-ride="carousel">
              <ol className="carousel-indicators">
                {MainSliderImages.map((item, i) => (
                  <li
                    key={`main_s_${i}`}
                    data-target="#my-slider"
                    data-slide-to={i}
                    className={i === 0 ? "active" : null}
                  />
                ))}
              </ol>
              <div className="carousel-inner">
                {MainSliderImages.map((item, i) => (
                  <div
                    className={`carousel-item ${i === 0 ? "active" : null}`}
                    key={`main_s_im_${i}`}
                  >
                    <div className="d-md-flex">
                      <div className="w3ls_banner_txt">
                        <h3 className="w3ls_pvt-title">{item.title}</h3>
                        <h3 className="w3ls_pvt-title">
                          <span>{item.subTitle}</span>
                        </h3>
                        <p className="text-sty-banner">
                          {item.description} <br />
                        </p>
                      </div>
                      <div className="banner-img">
                        <img
                          src={item.image}
                          alt={item.title}
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="what bg-li py-1" id="wedo">
          <div className="container py-xl-5 py-lg-3">
            <div className="icon-effect-w3">
              <span className="fa fa-child" />
            </div>
            <h2 className="tittle text-center font-weight-bold">What We Do</h2>
            <p className="sub-tittle text-center mt-3 mb-sm-5 mb-4">
              We are a team of Experienced, creative, designers & developers who
              work together to create beautiful, engaging digital experiences
              only for you.
            </p>
            <div className="row about-bottom-w3l text-center mt-4">
              {WhatWeDo.map((item, i) => (
                <div className="col-lg-3 about-grid py-1" key={`we_do_${i}`}>
                  <div className="about-grid-main">
                    <img
                      src={item.image}
                      alt={item.title}
                      className="img-fluid"
                    />
                    <h4 className="my-2">{item.title}</h4>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <section className="midd-w3 py-5" id="why">
          <div className="container py-xl-5 py-lg-3">
            <div className="row">
              <div className="col-lg-6 about-right-faq">
                <h6>Why Choose Us</h6>
                <h3 className="text-da">
                  {new Date().getFullYear() - 2019} Years of Industry Experience
                </h3>
                <p className="mt-4">
                  We are the leading and most demanding we agency of web and app
                  development in region and serving our best to clients.
                </p>
                <ul className="w3l-right-book mt-4">
                  <li>Web Development</li>
                  <li>Web Designing</li>
                  <li>Automation Application</li>
                  <li>App Development</li>
                  <li>Software Development</li>
                  <li>Business Growth</li>
                  <li>Technical Support</li>
                  <li>Web Security </li>
                  <li>Marketing Base</li>
                </ul>
              </div>
              <div className="col-lg-6 left-wthree-img text-right">
                <img
                  src="././images/b1.png"
                  alt="Why Choose Us"
                  className="img-fluid mt-5"
                />
              </div>
            </div>
          </div>
        </section>
        <div className="what bg-li" id="work">
          <div className="container py-xl-5 py-lg-3">
            <div className="icon-effect-w3">
              <span className="fa fa-folder-open" />
            </div>
            <h2 className="tittle text-center font-weight-bold">We Work</h2>
            <p className="sub-tittle text-center mt-3 mb-sm-5 mb-4">
              We are a team of Experienced, creative, designers & developers who
              work together to create beautiful, engaging digital experiences
              only for you.
            </p>
            <div className="row">
              <div className="col-lg-6 about-right-faq">
                <h6>Automation</h6>
                <ul className="w3l-right-book mt-4">
                  <li>Automate Facebook</li>
                  <li>Automate WhatsApp</li>
                  <li>App Development</li>
                  <li>Facebook Bot using AI</li>
                  <li>WhatsApp Bot using AI</li>
                  <li>THE Virtual Voice Assistant </li>
                </ul>
              </div>
              <div className="col-lg-6 about-right-faq">
                <h6>Database</h6>
                <ul className="w3l-right-book mt-4">
                  <li>Hotel Management System</li>
                  <li>School Management System</li>
                  <li>Library Management System</li>
                  <li>College Management System</li>
                  <li>Internship Drive Management System</li>
                </ul>
              </div>
              <div className="col-lg-12 about-right-faq mt-4">
                <h6>Machine Learning & Image Processing </h6>
                <ul className="w3l-right-book mt-4">
                  <li>Object Detection</li>
                  <li>Licence Plate Recognition System</li>
                  <li>Emotion Based Music Player System</li>
                  <li>Face Detection & Identification System</li>
                  <li>Sentimental Analysis for Facial Expression </li>
                  <li>
                    Driver Drowsiness & Vehicle Robbery Alert System Using SMS
                  </li>
                  <li>
                    Attendance Management using Face Recognition System and
                    generate sheet.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <section className="why bg-li pt-5" id="services">
          <div className="container py-xl-5 pt-3">
            <div className="icon-effect-w3">
              <span className="fa fa-rocket" />
            </div>
            <h2 className="tittle text-center font-weight-bold">
              Our Services
            </h2>
            <p className="sub-tittle text-center mt-3 mb-sm-5">
              Sometimes, a little help from a professional can make the biggest
              difference towards your success. As industry-leading Web
              Developer, we have witnessed the impact of our work with clients
              across the world. We offer a variety of Digital services across
              various industries - contact us to find out more.
            </p>
            <div className="row">
              {OurService.map((item, i) => (
                <div className="col-md-4 col-12 py-3" key={`our_ser_${i}`}>
                  <div className="ser-grd">
                    <div className="row">
                      <div
                        className={`col-md-3 col-2 text-center services-icon ${item.class}`}
                      >
                        <span className={`fa fa-${item.icon}`} />
                      </div>
                      <div className="col-md-9 col-10 text-left services-grid">
                        <h4>{item.title}</h4>
                        <p>{item.subTitle}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <ProjectGalleryComponent />
        <PartnersComponents />
      </main>
    </>
  );
}
