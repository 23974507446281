import React from "react";
import { Link } from "react-router-dom";
import BreadcrumbComponent from "../component/BreadcrumbComponent";

export default function Error404Page() {
  return (
    <>
      <BreadcrumbComponent>
        <li className="breadcrumb-item" aria-current="page">
          404 Error Page
        </li>
      </BreadcrumbComponent>

      <div className="error pb-5 pt-2 text-center">
        <div className="container pb-xl-5 pb-lg-3">
          <img src="images/error.png" alt="" className="img-fluid" />
          <h3 className="title-w3 text-bl my-3 font-weight-bold text-capitalize">
            Oops! This page can’t be found.
          </h3>
          <p className="sub-tittle text-center4">
            Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Ut enim ad minim veniam, quis nostrud exercitation
          </p>
          <Link to="/" className="btn button-style mt-5">
            Back To Home
          </Link>
        </div>
      </div>
    </>
  );
}
