import React from "react";
import { Link } from "react-router-dom";

const SocialLink = [
  {
    url: "https://facebook.com/Technowebtech/",
    icon: "facebook",
  },
  {
    url: "https://twitter.com/@technowebtech",
    icon: "twitter",
  },
  {
    url: "https://www.instagram.com/technowebtech/",
    icon: "instagram",
  },
];

export default function FooterComponent() {
  return (
    <div className="container-fluid p-0 m-0" id="project">
      <footer className="py-1">
        <div className="container">{/* <OwnedServices /> */}</div>
      </footer>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-1979597354663953"
        data-ad-slot={1746533921}
        data-ad-format="auto"
        data-full-width-responsive="true"
      />

      <div className="copy-bottom  py-1 border-top"  style={{backgroundColor:"#746e6e33"}}>
        <div className="container-fluid">
          <div className="d-md-flex px-md-3 position-relative text-center">
            <div className="social-icons-footer mb-md-0 mb-3">
              <ul className="list-unstyled">
                {SocialLink.map((item, i) => (
                  <li key={`social_icon_${i}`}>
                    <Link to={item.url} target="_blank">
                      <span className={`fa fa-${item.icon}`} />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="copy_right mx-md-auto mb-md-0 mb-3">
              <p className="text-bl let">
                ©2017-2022 Technowebtech. All rights reserved
              </p>
            </div>
            <Link
              to="#"
              onClick={() => window.scrollTo(0, 0)}
              className="move-top text-center"
            >
              <span className="fa fa-level-up" aria-hidden="true" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
