export const MainSliderImages = [
  {
    title: "We are the top level ",
    subTitle: "creative Developer agency",
    image: "./images/slide/banner.png",
    description:
      "Here you will get the most premium work by our best experienced Team",
  },
  {
    title: "Our services to give a",
    subTitle: "amazing experience",
    image: "./images/slide/banner2.gif",
    description:
      "We mainly focus on customers satisfaction towards the product we create for them.",
  },
  {
    title: "We love to create",
    subTitle: "Awesome Websites",
    image: "./images/slide/banner3.png",
    description:
      "We had a greate team of designers and developers continuously working to create a new design.",
  },
  {
    title: "We Are Design Awesome",
    subTitle: "Responsive Websites",
    image: "./images/slide/banner4.png",
    description:
      "All our design are highly responsive. which means our websites can be easily access from any screen size or OS.",
  },
  {
    title: "We love to create",
    subTitle: "Awesome Graphics.",
    image: "./images/slide/banner5.png",
    description:
      "Graphics are the only things which can make a whole website attractive. Our team work with various challenges to give you the best.",
  },
  {
    title: "We provide Web",
    subTitle: "Security Service",
    image: "./images/slide/banner7.png",
    description:
      "Web security is very immportent at present, to help inthis,We provide our clints a safe product after testing it in severral phases to reduse risk of web security.",
  },
];

export const WhatWeDo = [
  {
    image: "images/wedo/img1.gif",
    title: "Website Design",
    description: "We create awsome and attractive design and graphics.",
  },
  {
    image: "images/wedo/img2.gif",
    title: "Web Development",
    description:
      "We work on several Client & server based technologies for development.",
  },
  {
    image: "images/wedo/webupdate.gif",
    title: "Website Update",
    description:
      "Keep your website updated with our professional website support.",
  },
  {
    image: "images/wedo/img4.gif",
    title: "Graphic Design",
    description:
      "Our expert graphic designer creates lots of attractive Graphic.",
  },
  {
    image: "images/wedo/img3.gif",
    title: "App Development",
    description:
      "We also create Hybrid and Native android application as per clients need.",
  },
  {
    image: "images/wedo/automation.gif",
    title: "Automation",
    description:
      "To keep up with the trends surrounding AI today, we develop AI Application",
  },
  {
    image: "images/wedo/software.gif",
    title: "Software",
    description:
      "We design and build software that optimize your internal business process.",
  },
  {
    image: "images/wedo/hosting.gif",
    title: "Hosting",
    description:
      "Host your website with our customized Linux hosting services.",
  },
];

export const OurService = [
  {
    class: "icon-clr5",
    icon: "handshake-o",
    title: "Design and UX",
    subTitle: "Great Design attract more community.",
  },
  {
    class: " icon-clr1",
    icon: "bullhorn",
    title: "Marketing",
    subTitle:
      "Create your social presence and spread you across Social Plateforms",
  },
  {
    class: "icon-clr3",
    icon: "folder-open",
    title: "Graphics Designing",
    subTitle: "We create attractive logo, banners, Social icons and graphics.",
  },
  {
    class: "icon-clr6",
    icon: "globe",
    title: "Responsive Website",
    subTitle: "Automatically fits in all Displays",
  },

  {
    class: "icon-clr2",
    icon: "eye",
    title: "Awesome Support",
    subTitle: "24x7 Live support to the Client.",
  },
  {
    class: "icon-clr4",
    icon: "",
    title: "Unlimited Colors",
    subTitle: "We works with all available color to make website",
  },
  {
    class: "icon-clr5",
    icon: "line-chart",
    title: "Analytics",
    subTitle:
      "We provide you analytics of your website from various platforms.",
  },
  {
    class: "icon-clr1",
    icon: "headphones",
    title: "MLM Software",
    subTitle:
      "We also provide Network marketing or MLM software both as single or multi-lag plan.",
  },

  {
    class: "icon-clr3",
    icon: "lock",
    title: "Web Security",
    subTitle:
      "We provide web security to our clients for there website not to get easily attacked.",
  },
  {
    class: "icon-clr6",
    icon: "desktop",
    title: "Awesome Display",
    subTitle:
      "We have a great themes and texture which makes website look awesome.",
  },
  {
    class: "icon-clr2",
    icon: "gears",
    title: "Technical Support",
    subTitle:
      "Our expert technical team reach at you within 2 business  days after registering your queries at support.",
  },
  {
    class: "icon-clr4",
    icon: "lightbulb-o",
    title: "Domain & Hosting",
    subTitle:
      "We also help you to get domain and hosting and other services for your business.",
  },
  {
    class: "",
    icon: "",
    title: "",
    subTitle: "",
  },
];

export const OurPartners = [
  {
    image: "./images/partner/version.png",
    title: "VersionSofTech",
  },
  {
    image: "./images/partner/oflox.png",
    title: "Oflox",
  },
  {
    image: "./images/partner/preeminice.png",
    title: "Preeminice Lab",
  },
  {
    image: "./images/partner/visionToNextDoor.jpg",
    title: "Vision To Next Door",
  },
];
