import React from "react";
import { OurPartners } from "./DataJson";
export default function PartnersComponents() {
  return (
    <section className="partners bg-li" id="partners">
      <div className="container py-xl-5 py-lg-3">
        <div className="icon-effect-w3">
          <span className="fa fa-users" />
        </div>
        <h3 className="tittle text-center font-weight-bold">Our Partners</h3>
        <p className="sub-tittle text-center mt-3 mb-sm-5 mb-4">
          To provide You the best we handshake with some other professional
          agencies, have a look.
        </p>
        <div className="row grid-part text-center pt-4">
          <div className="col-md-1" />
          {OurPartners.map((item, i) => (
            <div className="col-md-2" key={`partner_${i}`}>
              <div className="parts-w3ls">
                <img src={item.image} alt={item.title} title={item.title} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
