import React, { useState } from "react";
import BreadcrumbComponent from "./../component/BreadcrumbComponent";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
export default function ContactPages() {
  const [FormInput, setFormInput] = useState({});
  const FormSubmitHandler = (e) => {
    e.preventDefault();
    fetch("https://technowebtech.com/contactapi.php", {
      method: "POST",
      body: JSON.stringify(FormInput),
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          setFormInput({})
          toast.success(data.data);
        } else {
          toast.error(data.data);
        }
      })
      .catch((err) => {
        toast.error("Network Error. please try again");
      });
  };

  const FornOnChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormInput((v) => ({ ...v, [name]: value }));
  };
  return (
    <>
      <ToastContainer />
      <BreadcrumbComponent>
        <li className="breadcrumb-item" aria-current="page">
          Contact Us
        </li>
      </BreadcrumbComponent>
      <div className="contact" id="contact">
        <div className="container pb-xl-5 pb-lg-3">
          <div className="row">
            <div className="col-lg-6">
              <img
                src="images/contact/contact.png"
                alt="contact"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 mt-lg-0 mt-5">
              <div className="contact-top1">
                <form
                  method="post"
                  className="contact-wthree-do"
                  onSubmit={FormSubmitHandler}
                >
                  <p>For any query or feedback mail us </p>
                  <a href="mailto:technowebtech@gmail.com">
                    <i className="fa fa-envelope"> </i> technowebtech@gmail.com
                  </a>
                  <div className="form-group mt-3">
                    <div className="row">
                      <div className="col-md-6">
                        <label>First name</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="First name"
                          name="fname"
                          value={FormInput.fname}
                          onChange={FornOnChangeHandler}
                          required={true}
                        />
                      </div>
                      <div className="col-md-6 mt-md-0 mt-4">
                        <label>Last name</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Last name"
                          name="lname"
                          value={FormInput.lname}
                          onChange={FornOnChangeHandler}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label>Mobile</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="xxxx xxxx xx"
                          name="mobile"
                          value={FormInput.mobile}
                          onChange={FornOnChangeHandler}
                          required={true}
                        />
                      </div>
                      <div className="col-md-6 mt-md-0 mt-4">
                        <label>Email</label>
                        <input
                          className="form-control"
                          type="email"
                          placeholder="example@mail.com"
                          name="email"
                          value={FormInput.email}
                          onChange={FornOnChangeHandler}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12">
                        <label>Your message</label>
                        <textarea
                          placeholder="Add your Description here"
                          name="message"
                          className="form-control"
                          required={true}
                          value={FormInput.message}
                          onChange={FornOnChangeHandler}
                          defaultValue={""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <button
                        type="submit"
                        className="btn btn-cont-w3 btn-block mt-4"
                        name="submit"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
