import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BreadcrumbComponent from "../component/BreadcrumbComponent";
import PartnersComponents from "./../component/PartnersComponents";
const year = new Date().getFullYear() - 2019;
export default function AboutPages() {
  const [TealLIst, setTealLIst] = useState([]);
  useEffect(() => {
    fetch("./js/Teamlist.json")
      .then((res) => res.json())
      .then((data) => {
        setTealLIst(data);
      });
  }, []);

  return (
    <>
      <BreadcrumbComponent>
        <li className="breadcrumb-item" aria-current="page">
          About Us
        </li>
      </BreadcrumbComponent>
      <div className="about-inner py-5">
        <div className="container pb-xl-5 pb-lg-3">
          <div className="row py-xl-4">
            <div className="col-lg-5 about-right-faq pr-5">
              <h6>Story Technowebtech</h6>
              <h3 className="mt-2 mb-3">
                Welcome to <span className="text-bg">T</span>echno
                <span className="text-bg">W</span>eb
                <span className="text-bg">T</span>
                ech
              </h3>
              <p className="text-justify">
                Technowebtech is serving its outstanding services in the area of
                website development along with the website solutions too.
              </p>
              <h4>We are modern creative agency </h4>
              <p className="mt-2 text-justify">
                We render unique and creative ideas to the website and design
                them smartly in an attractive manner. our team is fully
                specialized in responsive web design, static web design, and
                dynamic web design. With our expertise effort, we develop the
                best out of best. Also, we provide website solutions too.
              </p>
              <p className="mt-2 text-justify">
                We ensure the website is designed under the consideration of SEO
                fully, which makes us incomparable. We bring up latest trendy
                designs and themes and give an amazing fascination to your
                website.
              </p>
            </div>
            <div className="col-lg-7 welcome-right text-center mt-lg-0 mt-5">
              <img src="images/8.gif" alt="" className="img-fluid" />
            </div>
          </div>
          <p className="mt-2">
            Our company specifically selects web themes from our huge variety of
            innovative ideas and develop a visionary website to work upon. We
            provide free services such as responsive web design, domain and
            hosting depending upon the type of website customer demands and
            provide full customer support 24*7.
          </p>
        </div>
      </div>
      <section className="bottom-count py-5" id="stats">
        <div className="container py-xl-5 py-lg-3">
          <div className="row">
            <div className="col-lg-7 right-img-w3ls pl-lg-4 mt-lg-2 mt-4">
              <div className="bott-w3ls mr-xl-5">
                <h3 className="title-w3 text-bl mb-3 font-weight-bold">
                  Some of Company Real Facts
                </h3>
                <p className="title-sub-2 mb-3">
                  Take a brief of what we have done <br />
                  till now.
                </p>
              </div>
              <div className="row mt-5">
                <div className="col-sm-4 count-w3ls">
                  <h4>5+</h4>
                  <p>Own Project </p>
                </div>
                <div className="col-sm-4 count-w3ls mt-sm-0 mt-3">
                  <h4>480+</h4>
                  <p>Consultant</p>
                </div>
                <div className="col-sm-4 count-w3ls mt-sm-0 mt-3">
                  <h4>{year * 365}+</h4>
                  <p>Day Worked</p>
                </div>
              </div>
              <div className="row mt-sm-5 mt-4">
                <div className="col-sm-4 count-w3ls">
                  <h4>35+</h4>
                  <p>Completed Projects</p>
                </div>
                <div className="col-sm-4 count-w3ls mt-sm-0 mt-3">
                  <h4>{year * 365 * 6}+</h4>
                  <p>Coffee Cup</p>
                </div>
                <div className="col-sm-4 count-w3ls mt-sm-0 mt-3">
                  <h4>280+</h4>
                  <p>Website Updated</p>
                </div>
              </div>
            </div>
            <div className="col-lg-5 left-img-w3ls">
              <img src="./images/b3.png" alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      <section className="team bg-li py-5" id="team">
        <div className="container py-xl-5 py-lg-3">
          <h3 className="tittle text-center font-weight-bold">
            Our Awesome Team
          </h3>
          <p className="sub-tittle text-center mt-3 mb-sm-5 mb-4">
            We have been assisting individuals and businesses in defining and
            fulfilling their objectives. Having worked with industry-leading
            clients, we have managed to earn the reputation as a top Web
            Developer team. Take a look at our team which is always ready to
            help you in all problems related to your task and projects.
          </p>
          <div className="row ab-info second pt-lg-4">
            {TealLIst.map((team, i) => (
              <div className="col-lg-3 col-sm-6 ab-content text-center mt-lg-0 mt-4 mb-2">
                <div className="ab-content-inner">
                  <img src={team.image} alt={team.name} className="img-fluid" />
                  <div className="ab-info-con">
                    <h4 className="text-team-w3">{team.name} </h4>
                    <p>{team.role}</p>
                    <ul className="list-unstyled team-socil-w3pvts">
                      <li className="d-inline">
                        <Link to={team.facebook} target="_blank">
                          <span className="fa fa-facebook" />
                        </Link>
                      </li>
                      <li className="d-inline">
                        <Link to={team.instagram} target="_blank">
                          <span className="fa fa-instagram" />
                        </Link>
                      </li>
                      <li className="d-inline">
                        <Link to={team.linkedin} target="_blank">
                          <span className="fa fa-linkedin" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <PartnersComponents />
    </>
  );
}
