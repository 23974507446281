import React, { useEffect, useState } from "react";

export default function ProjectGalleryComponent() {
  const [GalleryImages, setGalleryImages] = useState([]);

  useEffect(() => {
    fetch("./js/ProjectGalleryImages.json")
      .then((res) => res.json())
      .then((data) => {
        setGalleryImages(data);
      });
  }, []);
  return (
    <section className="bottom-count" id="gallery">
      <div className="container py-xl-5 pt-3">
        <div className="bott-w3ls mr-xl-5 text-center">
          <h3 className="title-w3 text-bl mb-3 font-weight-bold">
            Company Works Gallery
          </h3>
          <p className="title-sub-2 mb-3">
            Have a close look at our Dynamic Project Done by our team.
          </p>
        </div>
        <div className="row gallery">
          {GalleryImages.map((img, i) => (
            <div
              className="col-lg-3 col-md-4 col-xs-6 thumb"
              key={`gallery_${i}`}
            >
                <figure>
                  <img
                    className="img-fluid img-thumbnail"
                    src={img.image}
                    alt={img.title}
                    title={img.title}
                  />
                </figure>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
