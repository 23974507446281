import React from "react";
import { Link } from "react-router-dom";

export default function BreadcrumbComponent(props) {
  return (
    <div className="banner_w3lspvt-2">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/" className="font-weight-bold">
            Home
          </Link>
        </li>
        {props.children}
      </ol>
    </div>
  );
}
