import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

const isActiveCalss = ({ isActive }) => {
  return `nav-link ${isActive === true ? "text-primary" : null}`;
};
export default function NavBarComponent() {
  const { pathname } = useLocation();
  return (
    <header className="fixed-top">
      <nav className="navbar navbar-expand-lg navbar-light">
        <Link className="navbar-brand" to="/">
          <img src="./images/twtlogo.png" alt="logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarColor02"
          aria-controls="navbarColor02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarColor02">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <NavLink className={(isActive) => isActiveCalss(isActive)} to="/">
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={(isActive) => isActiveCalss(isActive)}
                to="about"
              >
                About Us
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to="Pricing">
                Pricing
              </Link>
            </li> */}
            {pathname === "/" ? (
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Features
                </Link>
                <div
                  className="dropdown-menu nav-item"
                  aria-labelledby="dropdownMenuButton"
                >
                  <Link className="dropdown-item nav-item" to="#wedo">
                    What We do?
                  </Link>
                  <Link className="dropdown-item nav-item" to="#why">
                    Why Choose Us?
                  </Link>
                  <Link className="dropdown-item nav-item" to="#services">
                    Services
                  </Link>
                  <Link className="dropdown-item nav-item" to="#gallery">
                    Works Gallery
                  </Link>
                  <Link className="dropdown-item nav-item" to="#partners">
                    Partners
                  </Link>
                  <Link className="dropdown-item nav-item" to="#project">
                    Owned Project
                  </Link>
                </div>
              </li>
            ) : null}
            <li className="nav-item">
              <NavLink
                className={(isActive) => isActiveCalss(isActive)}
                to="contact"
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}
